@import "../../styles/Variables/Variables.scss";

.settingsection{
    margin-bottom: 50px;
    padding-bottom:30px;

    #settingsheading{
        font-size: 18px;
        font-family: $sffont-medium;
    }
    .settingslinks{
        color: #60677e !important;
        margin-right: 50px;
        font-size: 15px;
        font-family: $sffont-regular;
        text-decoration: none;
        letter-spacing: 0.15em;
        display: inline-block;
        padding: 15px 20px;
        position: relative;
        margin-left: -10px;   
    }

    .settingsgeneral{
        color: $primary-btncolor !important;
        margin-right: 50px;
        font-size: 15px;
        font-family: $sffont-regular;
        text-decoration: none;
        letter-spacing: 0.15em;
        display: inline-block;
        padding: 15px 20px;
        position: relative;
        margin-left: -10px;  
        border-bottom: 2px solid  $primary-btncolor;
    }

    
    .settingslinks:hover{
        color:  $primary-btncolor !important;
    }
    
    .settingsgeneral:hover{
        color:  $primary-btncolor !important;
      }

    .settingslinks:after {    
        background: none repeat scroll 0 0 transparent;
        bottom: 0;
        content: "";
        display: block;
        height: 1.6px;
        left: 50%;
        position: absolute;
        background:  $primary-btncolor;
        transition: width 0.3s ease 0s, left 0.3s ease 0s;
        width: 0;
      }
      .settingslinks:hover:after { 
        width: 100%; 
        left: 0; 
      }
     
     #layoutnav{
        border-radius: 5px;
     }

      #addform{
          margin-top: 30px;
          padding: 30px;
          border-radius: 10px;

                .savebtn{
                    border: none;
                    border-radius: 5px;
                    background-color: $primary-btncolor;
                    padding: 10px;
                    color: $white-color;
                    font-size: 14px;
                    margin-top: 10px;
                    font-family: $sffont-regular;

                    &:hover{
                        background-color: $secondary-btncolor;
                    }
                }
                .inputbox{
                    padding: 5px;
                    border: 1px solid #ced4da;
                    border-radius: 5px;
                    width: 100%;
                    font-size: 14px;
                }
                .inputbox1{
                    padding: 5px;
                    border: none;
                    border-radius: 5px;
                    width: 100%;
                    background-color: $darkthemecolor;
                    color: $lightthemetext;
                    font-family: $sffont-regular;
                }
                .formlabel{
                    font-family: $sffont-regular;
                    font-size: 14px;
                }
                .formlabel1{
                    color: #f8f9fa;
                    font-family: $sffont-regular;
                    font-size: 12px;
                }

                .logo{
                    border: 1px solid rgb(81 83 101 / 28%);
                    padding: 10px;
                    margin-bottom: 20px;
                }
                .editbtnsection{
                    position: relative;
                    margin-top: -37px;
                }
                
                #penicon{
                    margin-left:175px;
                    font-size: 14px;
                    background-color: $lightthemetext;
                    padding: 5px;
                    border-radius: 50%;
                    color: #e9ecef;
                    cursor: pointer;
                
                     &:hover{
                        background-color: $primary-btncolor;
                     }
                }
                
      }
}

 
.upload{
    border: 1px solid #dee2e6;
    background-color: #f8f9fa;
    padding: 6px 0px 0px 3px;
    width: 90px;
    margin-left: 205px;
    margin-top: -45px;
    border-radius:5px;
}
.uploadicon{
    color: $black-color;
    font-size: 18px;
    cursor: pointer;

    &:hover{
        color: $primary-btncolor;
     }
}
.crossicon{
    color: $black-color;
    font-size: 16px;
    margin-left: 40px;
    cursor: pointer;

    &:hover{
       color: $primary-btncolor;
     }

}

.navitemli{
    margin-left: 20px;
}



@media only screen and (max-width: 878px) {
   #layoutnav{
    display: flex !important;
   }
   .settingslinks{
    margin-right: 30px !important;
   }
}

@media only screen and (max-width: 548px) {

    .settingslinks{
     margin-right: 20px !important;
     font-size: 14px !important;
    }

    .settingsgeneral{
        font-size: 14px !important;
    }
 }


 @media only screen and (max-width: 400px) {

    .settingslinks{
     margin-right: 0px !important;
     font-size: 13px !important;
    }

    .settingsgeneral{
        font-size: 13px !important;
    }
 }

@media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}

