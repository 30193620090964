@import "/src/styles/Variables/Variables.scss";

#btnflex{
    display: flex;
}
#detail_actionbtn{
    background-color: #fff;
    color: #000;
    border: 1px solid #1f386d;
    border-radius: 3px;
    padding: 0px 10px;
    margin-right: 10px;
    height: 30px;

    &:hover{
        background-color: #1f386d;
        color: #fff;
    }
}

#view_actionbtn{
    background-color: #fff;
    color: #000;
    border: 1px solid #2e2e2e;
    border-radius: 3px;
    padding: 0px 10px;
    margin-right: 10px;
    height: 30px;

    &:hover{
        background-color: #2e2e2e;
        color: #fff;
    }
}

#action_btn_warning{
    background-color: #fff;
    border: 1px solid #fe0001;
    color: #000;
    border-radius: 3px;
    padding: 0px 10px;
    margin-right: 10px;
    height: 30px;

    &:hover{
        background-color: #fe0001;
        color: #fff;
    }
}