@import "../../styles/Variables/Variables.scss";


#addform{
    width: 100%;
    padding: 10px;
    border-radius: 5px;
    margin-top: 30px;
    margin-bottom: 20px;
   
    #addheading{
        font-size: 18px;
        font-family: $sffont-regular;
        margin-left: 10px;
    }

    .profilepicdiv{
        padding: 10px;
        border-radius: 5px;
    }

    .profilepic{
        width: 100px;
        height: 100px;
        border-radius: 50%;
        border: 1px solid #21252975;
        padding: 5px;
    }
    .namesection{
        display: inline-block;
    }

    .profilename{
        font-size: 16px;
        font-family: $sffont-medium;
        margin-left: 20px;
        margin-top: 30px;
    }
    .profileemail{
        font-size: 14px;
        font-family: $sffont-regular;
        margin-left: 20px;
    }
   


        #empdiv{
            padding:5px 0px 0px 15px;
            margin-bottom: 10px;
            border-radius: 5px;
            height: 55px;

            .title{
                font-family: $sffont-regular;
                font-size: 13px;
            }

            .empname{
                font-family: $sffont-medium;
                font-size: 14px;
            }
        }

        
        .arrowback{
            float: right;
            background-color: #dde2eb;
            padding: 10px;
            color: #fe0001;
            cursor: pointer;
            border-radius: 3px;
    
              &:hover{
                background-color: #fe0001;
                color: #fff;
              }
          }
}

/* Absolute Center Spinner */
.overlay{	
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    height:100%;
    background: rgba(0,0,0,0.6);
  }
  .spinner_center {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;  
  }
  .circle_spinner {
    width: 60px;
    height: 60px;
    border: 3px #aaa solid;
    border-top: 3px #c31e25 solid;
    border-radius: 50%;
    animation: sp-anime 0.8s infinite linear;
  }
  @keyframes sp-anime {
    100% { 
      transform: rotate(360deg); 
    }
  }



@media only screen and (max-width: 600px) {
    #dashboardcont2{
        width: $dashwidth;
        left: $dashleft;
        position: relative;
        padding: $dashboardpadding;
    }
}

